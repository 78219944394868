/**
 * 油耗监控用到的接口
 */
import http from '@/utils/httpRequest.js'
import config from '@/utils/config.js'
const fuelConMonitor = {
	//获取车牌号列表
	getlicensePlateList(){
		return http({
			url: http.adornUrl('vehicle/getlicensePlateList'),
			method: "get"
		})
	},
	//油耗监控列表页面
	fuelConList(data){
		return http({
			url: http.adornUrl('consumption/page'),
			method: "get",
			params:data
		})
	},
	//查看油耗详情
	fuelInfo(data){
		return http({
			url: http.adornUrl('consumption/info'),
			method: "get",
			params:data
		})
	},
	//车辆类型
	getCarType(){
		return http({
			url: http.adornUrl('vehicleTypes/list'),
			method: "get"
		})
	},
	//用车人
	getUserList(){
		return http({
			url: http.adornUrl('user/tenantUserList'),
			method: "get"
		})
	},
	//获取车牌号列表（过滤掉已排班的车辆）
	getScheduleLicensePlate(){
		return http({
			url: http.adornUrl('vehicleSchedule/getScheduleLicensePlate'),
			method: "get"
		})
	}
}
export default fuelConMonitor;