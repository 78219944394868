/**
 * 忘记密码用到的接口
 */
import http from '@/utils/httpRequest.js'
import config from '@/utils/config.js'
const forgotPassword = {
	//获取验证码接口
	getCode(data){
		return http({
			url: http.adornUrl('sms/getVerificationCode?phone='+data),
			method: "get"
		})
	},
	//修改密码
	updatePwd(data){
		return http({
			url: http.adornUrl('sms/updatePwd'),
			method: "put",
			data
		})
	}
}
export default forgotPassword;