<template>
	<div id="app">
		<router-view v-if="isRouterAlive"> </router-view>
	</div>
</template>

<script>
	export default {
		name: 'App',
		provide() {//页面刷新 确保登录信息实时刷新
			return {
				reload: this.reload
			}
		},
		data() {
			return {
				isRouterAlive: true
			}
		},
		methods: {
			reload() {
				this.isRouterAlive = false
				this.$nextTick(function() {
					this.isRouterAlive = true
				})
			}
		}
	}
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		width: 100%;
		height: 100%;
	}
	html,body{
		width: 100%;
		height: 100%;
		margin: 0;
		overflow-x: hidden;
		/* min-width:1024px; */
	}
	.el-pagination{
		width: 100%;
	}
	.mobile-show {
		display: none !important;
	}
	.carnum-link{
		text-decoration-line: underline;		
		color: #0050EF;
		cursor: pointer;
	}
	.search-btn .el-input__suffix{
		margin-top: -3px;
	}
	
	@media(max-width:992px) {
		.el-pagination{
			overflow-x: auto;
			overflow-y: hidden;
		}
		/* .mobile-show {
			display: block !important;
		}
	
		.mobile-hide {
			display: none !important;
		} */
	}
	@media (max-width: 768px){
		.el-pagination>.el-pagination__jump, .el-pagination>.el-pagination__sizes {
			display: none!important;
		}
	}
	
	.markerInfo {
	    background: rgba(255, 255, 255, 0.7);
	    padding: 2px 5px;
	    border: 1px solid #ccc;
	    white-space: nowrap;
	}
	.amap-logo,
	.amap-copyright {
		display: none !important; 
	}
</style>
