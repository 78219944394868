/**
 * 租户版本用到的接口
 */
import http from '@/utils/httpRequest.js'
import config from '@/utils/config.js'
const tenantVersion = {
	//租户权限分页列表
	getTenantVersion(data){
		return http({
			url: http.adornUrl('tenantVersion/page'),
			method: "get",
			params:data
		})
	},
	//租户版本列表(不分页)
	getTenantVersionList(data){
		return http({
			url: http.adornUrl('tenantVersion/list'),
			method: "get"
		})
	},
	//添加租户权限版本
	addTenantVersion(data){
		return http({
			url: http.adornUrl('tenantVersion'),
			method: "post",
			data
		})
	},
	//修改租户权限版本
	updateTenantVersion(data){
		return http({
			url: http.adornUrl('tenantVersion'),
			method: "put",
			data
		})
	},
	//绑定权限
	bindPermission(data){
		return http({
			url: http.adornUrl('tenantVersion/menu'),
			method: "put",
			data
		})
	},
	//租户权限详情
	permissionDetails(id){
		return http({
			url: http.adornUrl('tenantVersion/'+id),
			method: "get"
		})
	},
	//删除租户版本
	deleteTenantVersion(id){
		return http({
			url: http.adornUrl('tenantVersion/'+id),
			method: "delete"
		})
	}
}
export default tenantVersion;