/**
 * 租户管理用到的接口
 */
import http from '@/utils/httpRequest.js'
import config from '@/utils/config.js'
const tenant = {
	//角色列表
	roleList(data){
		return http({
			url: http.adornUrl('role/list'),
			method: "get"
		})
	},
	//添加租户
	addTenant(data){
		return http({
			url: http.adornUrl('tenant'),
			method: "post",
			data
		})
	},
	//租户列表
	tenantList(data){
		return http({
			url: http.adornUrl('tenant/page'),
			method: "get",
			params:data
		})
	},
	//删除租户
	deleteTenant(id){
		return http({
			url: http.adornUrl('tenant/')+id,
			method: "delete"
		})
	},
	//修改租户
	editTenant(data){
		return http({
			url: http.adornUrl('tenant'),
			method: "put",
			data
		})
	}
}
export default tenant;