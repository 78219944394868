/**
 * api接口的统一出口
 */
const api = {};
const requireComponent = require.context('./files', false, /\.js$/)
requireComponent.keys().forEach(fileName => {
  const componentName = fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
  const componentConfig = requireComponent(fileName)
  api[componentName] = componentConfig.default
})
export default api;
