//该文件用于创建Vuex中最为核心的store
import Vue from 'vue'
//引入Vuex
import Vuex from 'vuex'
//应用Vuex插件
Vue.use(Vuex)

//准备actions——用于响应组件中的动作
/* const actions = {
	
	jiaOdd(context, value) {
		console.log('actions中的jiaOdd被调用了')
		if (context.state.sum % 2) {
			context.commit('JIA', value)
		}
	},
	jiaWait(context, value) {
		console.log('actions中的jiaWait被调用了')
		setTimeout(() => {
			context.commit('JIA', value)
		}, 500)
	}
} */
//准备mutations——用于操作数据（state）
/* const mutations = {
	JIA(state,value){
		console.log('mutations中的JIA被调用了')
		state.sum += value
	},
	JIAN(state,value){
		console.log('mutations中的JIAN被调用了')
		state.sum -= value
	}
} */
//准备state——用于存储数据
const state = {
	sum: 0, //当前的和
	car_coordinates: {},// 单车坐标
	more_car_coordinates: [],// 多车坐标
}
// 监听state 对象的值的最新状态(计算属性)
const getters = {
	//单车坐标
	getCar(state) {
		return state.car_coordinates;
	},
	//多车坐标
	getMoreCar(state) {
		return state.more_car_coordinates;
	},

}
//唯一一个可以修改state 值的方法(同步)
const mutations = {
	//单车坐标
	updateCar(state, car_coordinates) {
		state.car_coordinates = car_coordinates;
	},
	//多车坐标
	updateMoreCar(state, more_car_coordinates) {
		state.more_car_coordinates = more_car_coordinates;
	},
}
// 异步执行 mutations 方法
const actions = {
	asyncUpdayteCar(context, car_coordinates) {
		context.commit("updateCar", car_coordinates);
	},
	asyncUpdayteMoreCar(context, more_car_coordinates) {
		context.commit("updateMoreCar", more_car_coordinates);
	},
}


//创建并暴露store
export default new Vuex.Store({
	getters,
	actions,
	mutations,
	state,
})