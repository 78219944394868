/**
 * 部门管理用到的接口
 */
import http from '@/utils/httpRequest.js'
import config from '@/utils/config.js'
const department = {
	//部门列表(能筛选供列表页使用)
	departList(data){
		return http({
			url: http.adornUrl('dept/list'),
			method: "get",
			params:data
		})
	},
	//部门列表(供选择部门使用，不能筛选)
	departData(){
		return http({
			url: http.adornUrl('dept/tree'),
			method: "get"
		})
	},
	//新增部门
	addDepart(data){
		return http({
			url: http.adornUrl('dept'),
			method: "post",
			data
		})
	},
	//根据id查询部门信息
	departInfo(id){
		return http({
			url: http.adornUrl('dept/'+id),
			method: "get"
		})
	},
	//删除部门
	deleteDepart(id){
		return http({
			url: http.adornUrl('dept/')+id,
			method: "delete"
		})
	},
	//修改部门
	editDepart(data){
		return http({
			url: http.adornUrl('dept'),
			method: "put",
			data
		})
	}
}
export default department;