/**
 * 公告接口
 */
import http from '@/utils/httpRequest.js'
const getPlacard = {
	//公告列表
	gongGaoList(data) {
		return http({
			url: http.adornUrl('menu'),
			method: "get"
		})
	},
	//公告列表--只获取消息类型的公告
	gongGaoListXiaoxi(data) {
		return http({
			url: http.adornUrl('noticeAnnouncement/list'),
			method: "get"
		})
	},
	//添加公告
	addPlacard(data) {
		return http({
			url: http.adornUrl('noticeAnnouncement/addAnnouncement'),
			method: "post",
			data
		})
	},
	//编辑公告
	editPlacard(data) {
		return http({
			url: http.adornUrl('noticeAnnouncement'),
			method: "put",
			data
		})
	},
	//获取公告类型
	getPlacardType() {
		return http({
			url: http.adornUrl('noticeAnnouncement/getAnnouncementType'),
			method: "get"
		})
	},
	//公告列表
	getPlacardList(data) {
		return http({
			url: http.adornUrl('noticeAnnouncement/page'),
			method: "get",
			params: data
		})
	},
	//查询公告信息详情接口
	getGongInfo(id) {
		return http({
			url: http.adornUrl('noticeAnnouncement/' + id),
			method: "get"
		})
	},
	//删除公告
	deletePlacard(id) {
		return http({
			url: http.adornUrl('noticeAnnouncement/') + id,
			method: "delete"
		})
	},
	//获取检查更新
	getUpdates() {
		return http({
			url: http.adornUrl('noticeAnnouncement/latestVersion'),
			method: "get"
		})
	},
	//pc-首页获取公告列表(包含未读，已读状态)
	getNotice() {
		return http({
			url: http.adornUrl('noticeAnnouncement/systemList'),
			method: "get"
		})
	}


}
export default getPlacard;