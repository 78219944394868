import store from '@/store'
import { Message } from 'element-ui'
import {webSocketDomain} from './utils/config.js'

//var url = 'ws://127.0.0.1:5173/websocket/'
var url = webSocketDomain ;
var ws;
var tt;
var lockReconnect = false;//避免重复连接
var clientId = localStorage.getItem("clientId")//缓存中取出客户端id



var websocket = {
  car_coordinates_yuan: '',//车辆原始坐标
  car_coordinates: [],//车辆转化后的坐标
  testtt: 888,
  Init: function (clientId,deviceNumberList) {
    this.testtt = 999;
    if ("WebSocket" in window) {
      ws = new WebSocket(url + clientId);
    } else if ("MozWebSocket" in window) {
      ws = new MozWebSocket(url + clientId);
    } else {
      console.log("您的浏览器不支持 WebSocket!");
      return;
    }

    ws.onmessage = function (e) {
      // console.log("接收消息@@:" + e.data)
      this.car_coordinates_yuan = JSON.parse(e.data); // 建达测试数据传递过来的测试数据是json 格式的字符串，所以要把json字符串转成json对象
      //this.car_coordinates_yuan = eval('(' + e.data + ')');
      if (this.car_coordinates_yuan.code === '6666') {
        //console.log('转化完的数组车辆坐标##', this.car_coordinates_yuan.data.ddata);
        //this.car_coordinates = JSON.stringify(this.car_coordinates_yuan);  // 建达
        // this.car_coordinates = JSON.stringify(this.car_coordinates_yuan.data.ddata);
        this.car_coordinates = this.car_coordinates_yuan.data.ddata;
        // console.log("***:", this.car_coordinates[0].id)
        // console.log('属性车辆坐标$$$', this.car_coordinates);
        if (this.car_coordinates[0].id) {
          store.dispatch("asyncUpdayteMoreCar", { more_car: this.car_coordinates });// 多辆车
        }
        else {
          store.dispatch("asyncUpdayteCar", { one_car: this.car_coordinates });// 单辆车
        }
        this.testtt = 10101;
      }
      heartCheck.start()
      if (e.data == 'ok') {//心跳消息不做处理
        return
      }
      //messageHandle(e.data)
    }

    ws.onclose = function () {
      console.log("连接已关闭")
      // Message({
      //   message: '连接已关闭',
      //   type: 'error',
      // });
      reconnect(clientId);
    }

    ws.onopen = function () {
      console.log("连接成功");
      // ws.send(JSON.stringify(getClientMsgTemplate(localStorage.getItem("Authorization"), "1", "myTopic1")))
	  ws.send(JSON.stringify(getClientMsgTemplate(localStorage.getItem("Authorization").slice(7), "1", deviceNumberList)))
      Message({
        message: '连接-订阅成功',
        type: 'success',
      });
      heartCheck.start();
    }

    ws.onerror = function (e) {
      console.log("数据传输发生错误");
      // Message({
      //   message: '数据传输发生错误',
      //   type: 'error',
      // });
      reconnect(clientId)
    }
  },
  Send: function (sender, reception, body, flag) {
    let data = {
      sender: sender,
      reception: reception,
      body: body,
      flag: flag
    }
    let msg = JSON.stringify(data)
    console.log("发送消息：" + msg)
    ws.send(msg)
  },
  SendDevice: function (deviceNumberList1) {
	  console.log("发送想要监听设备的设备号");
	  console.log("deviceNumberList1:",deviceNumberList1)
    ws.send(JSON.stringify(getClientMsgTemplate(localStorage.getItem("Authorization").slice(7), "1", deviceNumberList1)))
  },
  cancelSend: function (deviceNumberList) {
	  console.log("发送不想监听设备的设备号");
	  console.log("deviceNumberList:",deviceNumberList)
    ws.send(JSON.stringify(getClientMsgTemplate(localStorage.getItem("Authorization").slice(7), "2", deviceNumberList)))
  },
  Close:function(){
	  //关闭连接
	  ws.close();
  },
  getWebSocket() {
    return ws;
  },
  getStatus() {
    if (ws.readyState == 0) {
      return "未连接";
    } else if (ws.readyState == 1) {
      return "已连接";
    } else if (ws.readyState == 2) {
      return "连接正在关闭";
    } else if (ws.readyState == 3) {
      return "连接已关闭";
    }
  }
}

export default websocket;

//根据消息标识做不同的处理
function messageHandle(message) {
  let msg = JSON.parse(message)
  switch (msg.flag) {
    case 'command':
      console.log("指令消息类型")
      break;
    case 'inform':
      console.log("通知")
      break;
    default:
      console.log("未知消息类型")
  }
}

function reconnect(sname) {
  if (lockReconnect) {
    return;
  };
  lockReconnect = true;
  //没连接上会一直重连，设置延迟避免请求过多
  tt && clearTimeout(tt);
  tt = setTimeout(function () {
    console.log("执行断线重连...")
    websocket.Init(sname);
    lockReconnect = false;
  }, 4000);
}

//心跳检测
var heartCheck = {
  //timeout: 1000 * 60 * 3,原先
  timeout: 10000,
  timeoutObj: null,
  serverTimeoutObj: null,
  start: function () {
     
    // console.log('开始心跳检测');
    // var self = this;
    // this.timeoutObj && clearTimeout(this.timeoutObj);
    // this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
    // this.timeoutObj = setTimeout(function () {
    //   //这里发送一个心跳，后端收到后，返回一个心跳消息，
    //   //onmessage拿到返回的心跳就说明连接正常
    //   console.log('心跳检测...');
    //   ws.send(JSON.stringify(getClientMsgTemplate('jat110', '4', 'ping')))
    //   self.serverTimeoutObj = setTimeout(function () {
    //     if (ws.readyState != 1) {
    //       ws.close();
    //     }
    //     // createWebSocket();
    //   }, self.timeout);

    // }, this.timeout);
    
    
  }
}


//开始订阅

// 订阅数据类型
export function getClientMsgTemplate(clientToken, dtype, topic) {
  var clientMsgTemplate = {
    code: '0000',//0000表示成功其他为失败
    from: clientToken,
    to: 'server', //接收方server客户端发给服务端,alluser发给所有用户,client服务端发给客户端,user发给指定用户
    type: '2', //消息类型,1用户对用户2用户对服务器3服务器对用户4暂未使用
    time: "2022-8-30",//发送时间预留字段
    data: {
      dtype: dtype,//连接类型,1客户端订阅服务端主题,2客户端取消服务端订阅,3服务端推送给客户端对应主题数据,4心跳连接
      dvalue: topic, //连接类型为1或2时主题 连接类型为4时固定值ping
      ddata: [] //服务端给客户端的数据
    }
  };
  return clientMsgTemplate;
}

