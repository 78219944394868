/**
 * 公务用车-车务申报用到的接口
 */
import http from '@/utils/httpRequest.js'
const vehicleDeclaration = {
	//保险种类列表
	processTypeList(){
		return http({
			url: http.adornUrl('insurance/getProcessTypeList'),
			method: "get"
		})
	},
	//费用申请-保险管理
	addInsurance(data){
		return http({
			url: http.adornUrl('insurance/addInsurance'),
			method: "post",
			data
		})
	},
	//保险、年检详情
	getTrafficInfo(data){
		return http({
			url: http.adornUrl('traffic/getTrafficInfo'),
			method: "get",
			params:data
		})
	},
	//修改保险
	editInsurance(data){
		return http({
			url: http.adornUrl('insurance'),
			method: "put",
			data
		})
	},
	//添加年检管理
	addInspection(data){
		return http({
			url: http.adornUrl('annualInspection/addInspection'),
			method: "post",
			data
		})
	},
	//修改年检管理
	annualInspection(data){
		return http({
			url: http.adornUrl('annualInspection'),
			method: "put",
			data
		})
	},
	//维修管理
	addRepair(data){
		return http({
			url: http.adornUrl('repair/addRepair'),
			method: "post",
			data
		})
	},
	//修改维修管理
	editRepair(data){
		return http({
			url: http.adornUrl('repair'),
			method: "put",
			data
		})
	},
	//获取车牌号
	getlicensePlateListMonitor(){
		return http({
			url: http.adornUrl('vehicle/getlicensePlateListMonitor'),
			method: "get"
		})
	}
}
export default vehicleDeclaration;