/**
 * 统计分析
 */
import http from '@/utils/httpRequest.js'
import config from '@/utils/config.js'
const staAnalysis = {
	// 获取任务排名
	getDriverTaskRanking(data){
		return http({
			url: http.adornUrl('displayScreen/getDriverTaskRanking'),
			method: "get",
			params:data
		})
	},
	//获取违章排名
	getDriverViolationRanking(data){
		return http({
			url: http.adornUrl('displayScreen/getDriverViolationRanking'),
			method: "get",
			params:data
		})
	},
	//获取车辆里程排名
	getVehicleMileageRanking(data){
		return http({
			url: http.adornUrl('displayScreen/getVehicleMileageRanking'),
			method: "get",
			params:data
		})
	},
	//获取车辆使用排名
	getCarUsageRanking(data){
		return http({
			url: http.adornUrl('displayScreen/getCarUsageRanking'),
			method: "get",
			params:data
		})
	},
	//获取车务费用总览数据
	getBusFareRanking(data){
		return http({
			url: http.adornUrl('displayScreen/getAnalyzeBusFareRanking'),
			method: "get",
			params:data
		})
	},
	//获取车辆用途统计数据
	getVehicleUsage(data){
		return http({
			url: http.adornUrl('displayScreen/getVehicleUsage'),
			method: "get",
			params:data
		})
	},
	//获取车务费用报表数据
	getMonthlyServiceCost(data){
		return http({
			url: http.adornUrl('displayScreen/getMonthlyServiceCost'),
			method: "get",
			params:data
		})
	},
	//获取总体一览表数据
	getAnalysisOverview(data){
		return http({
			url: http.adornUrl('displayScreen/getAnalysisOverview'),
			method: "get",
			params:data
		})
	}
}
export default staAnalysis;