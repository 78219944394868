/**
 * 车辆分析
 */
import http from '@/utils/httpRequest.js'
import config from '@/utils/config.js'
const vehicleAnalysis = {
	// 车辆详情
	getCarInfo(data){
		return http({
			url: http.adornUrl('vehicleAnalysis/vehicleInfo'),
			method: "get",
			params:data
		})
	},
	//设备品牌列表
	getDeviceBrandList(){
		return http({
			url: http.adornUrl('deviceBrand/list'),
			method: "get"
		})
	},
	//设备类型列表
	getDeviceTypeList(){
		return http({
			url: http.adornUrl('deviceType/list'),
			method: "get"
		})
	},
	//用车里程概况
	getVehicleOverview(data){
		return http({
			url: http.adornUrl('vehicleAnalysis/applicationMileageOverview'),
			method: "get",
			params:data
		})
	},
	//用车油耗概况
	getVehicleOilOverview(data){
		return http({
			url: http.adornUrl('vehicleAnalysis/applicationFuelOverview'),
			method: "get",
			params:data
		})
	},
	//车务概况
	getVehicleServices(data){
		return http({
			url: http.adornUrl('vehicleAnalysis/serviceOverview'),
			method: "get",
			params:data
		})
	},
	//行驶里程统计
	getMileageStatistics(data){
		return http({
			url: http.adornUrl('vehicleAnalysis/drivingMileageStatistics'),
			method: "get",
			params:data
		})
	},
	//车务费用统计
	getBusFareRanking(data){
		return http({
			url: http.adornUrl('vehicleAnalysis/serviceExpenseStatistics'),
			method: "get",
			params:data
		})
	},
	//用车记录
	getCarRecords(data){
		return http({
			url: http.adornUrl('vehicleAnalysis/applicationDetails'),
			method: "get",
			params:data
		})
	},
	//车务申请
	getVehicleApply(data){
		return http({
			url: http.adornUrl('vehicleAnalysis/serviceDetails'),
			method: "get",
			params:data
		})
	},
	//用车部门列表
	getVehicleDepartmentList(){
		return http({
			url: http.adornUrl('officialVehicles/getDeptList'),
			method: "get"
		})
	},
	//用车途径列表
	getVehicleUsageList(){
		return http({
			url: http.adornUrl('officialVehicles/getVehicleUseToList'),
			method: "get"
		})
	},
	//车务类型列表
	getVehicleServiceTypeList(){
		return http({
			url: http.adornUrl('traffic/vehicleServiceTypeList'),
			method: "get"
		})
	},
	//审批状态列表
	getApprovalStatusList(){
		return http({
			url: http.adornUrl('traffic/approvalStatusList'),
			method: "get"
		})
	},
	//用车人列表
	getCarUserList(){
		return http({
			url: http.adornUrl('user/tenantUserList'),
			method: "get"
		})
	}
}
export default vehicleAnalysis;