import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import qs from 'qs'
import merge from 'lodash/merge'
import {
	domain,
	picDomain
} from './config'
import wx from "weixin-js-sdk"

let currentVue = null;

const http = axios.create({
	timeout: 1000 * 60,
	withCredentials: false,
	headers: {
		'Content-Type': 'application/json; charset=utf-8'
	}
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
	console.log("config:",config);
	// if(config.url.indexOf("https://restapi.amap.com/v4/grasproad/driving")  == -1 || config.Authorization != 'no'){		
	if(config.Authorization != 'no'){		
		config.headers['Authorization'] = localStorage.getItem("Authorization") ? localStorage.getItem(
			"Authorization") : '';
	}
	if (config.currentVue) {
		currentVue = config.currentVue
	}
	// 只针对get方式进行序列化
	if (config.method === 'get' || config.method === 'GET') {
		config.paramsSerializer = function(params) {
			return qs.stringify(params, {
				arrayFormat: 'repeat'
			})
		}
	}
	return config
}, error => {
	return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
	if (response.status === 200) {
		return response.data;

	} else {
		console.log("status:", response.status);
		return;
	}
}, error => {
	console.log("error:", error);
	// 错误信息提示
	if(error.response.status == 403){
		let Authorization = localStorage.getItem("Authorization");
		localStorage.removeItem('userinfo');
		localStorage.removeItem('Authorization');
		let path = router.app._route.path;
		if(path.includes("/h5/track-query") || path.includes("/h5/online-map") || path.includes("/h5/track-playback")){
			console.log("不跳转登录页面，回退到小程序上一页面",path);
			let redirectPath = "";
			if(path.includes('/track-query')){
				redirectPath = "/packageWorkbench/track-query/track-query";
			}else if(path.includes('/online-map')){
				redirectPath = "/packageWorkbench/online-map/online-map";
			}else if(path.includes('/track-playback')){
				redirectPath = "/packageWorkbench/vehicle-record/track-playback";
			}
			redirectPath = redirectPath + "?Authorization=" + Authorization;
			wx.miniProgram.redirectTo({url: redirectPath})
			console.log("redirectPath:",redirectPath);
		}else{
			router.push('/login')
		}
	} else if (error.response.data == undefined) {
		Vue.prototype.$message({
			message: error.message,
			type: 'warning'
		});
	} else if(error.response.data.error) {
		Vue.prototype.$message({
			message: error.response.data.error,
			type: 'warning'
		});
	}else {
		Vue.prototype.$message({
			message: error.response.data,
			type: 'warning'
		});
	}

	return Promise.reject(error);
})

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = (actionName) => {
	return domain + actionName
}

/**
 * im请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornImUrl = (actionName) => {
	return picDomain + actionName
}


/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
	var defaults = {
		't': new Date().getTime()
	}
	return openDefultParams ? merge(defaults, params) : params
}

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
	var defaults = {
		't': new Date().getTime()
	}
	data = openDefultdata ? merge(defaults, data) : data
	return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data)
}

var uploadFile = function(url, file) {
	let config = {
		// 添加请求头
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}
	let param = new FormData()
	// 通过append向form对象添加数据
	Array.from(file).map(item => {
		param.append('file', item);
	})
	return axios.post(url, param, config);
}

/**
 *提交post请求发送的数据为查询字符串，key=val&key=val  图片上传
 */
export function post(url, data) {
	return service({
		method: "post",
		url,
		data: qs.stringify(data),
		timeout: 10000,
		headers: {
			"Content-Type": "multipart/form-data",
			"Access-Control-Allow-Origin": "*",
		}
	})
}

	export default http
export {
	uploadFile
}