/**
 * 钉钉免登录用到的接口
 */
import http from '@/utils/httpRequest.js'
import config from '@/utils/config.js'
const dingtalk = {
	//获取企业内部应用的access_token
	accessToken(){
		return http({
			url: '/apidingtalk/v1.0/oauth2/accessToken',
			method: "post",
			Authorization:'no',
			data:{
				appKey:'dingme3lmtku3orldiqq',
				appSecret:'PTf15sMt_fyxVPAa166TZifJx5n1XnezPqSyukR16L8fcw4Kt623fUQw7Ks54K49'
			}
		})
	},
	//通过免登码获取用户信息
	getuserinfo(params,data){
		return http({
			url: '/oapidingtalk/topapi/v2/user/getuserinfo',
			method: "post",
			Authorization:'no',
			params,
			data
		})
	},
	//查询用户详情
	getuserdetails(params,data){
		return http({
			url: '/oapidingtalk/topapi/v2/user/get',
			method: "post",
			Authorization:'no',
			params,
			data
		})
	}
}
export default dingtalk;