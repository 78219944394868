/**
 * 统计分析
 */
import http from '@/utils/httpRequest.js'
import config from '@/utils/config.js'
const statisticalAnalysis = {
	// 司机列表
	getDriverList(){
		return http({
			url: http.adornUrl('officialVehicles/getDriverList'),
			method: "get"
		})
	}
}
export default statisticalAnalysis;