/**
 * 轨迹查车用到的接口
 */
import http from '@/utils/httpRequest.js'
import config from '@/utils/config.js'
const trackQuery = {
	//轨迹纠偏
	trajectoryRectify(data){
		return http({
			url: "https://restapi.amap.com/v4/grasproad/driving?key=739ad4caef27d59f52a7c4c5dcfab970",
			method: "post",
			Authorization:'no',
			data
		})
	},
	//获取当前时间段内设备的行驶里程
	equipmentMileageByNumber(data){
		return http({
			url: http.adornUrl('officialVehicles/getEquipmentMileageByNumber'),
			method: "get",
			params:data
		})
	}
}
export default trackQuery;