/**
 * 车辆排班用到的接口
 */
import http from '@/utils/httpRequest.js'
const vehicleScheduling = {
	//菜单列表
	menuList(data){
		return http({
			url: http.adornUrl('menu'),
			method: "get"
		})
	},
	//车辆分组
	vehicleGroupList(data){
		return http({
			url: http.adornUrl('workforceManagement/getGroupList'),
			method: "get"
		})
	},
	//公司常用地址
	addressList(data){
		return http({
			url: http.adornUrl('officialVehicles/getCompanyAddressList'),
			method: "get"
		})
	},
	//新增排班
	addSchedule(data){
		return http({
			url: http.adornUrl('vehicleSchedule/addSchedule'),
			method: "post",
			data
		})
	},
	//修改排班
	editSchedule(data){
		return http({
			url: http.adornUrl('vehicleSchedule'),
			method: "put",
			data
		})
	},
	//修改排班状态
	updateScheduleStatus(data){
		return http({
			url: http.adornUrl('vehicleSchedule/updateScheduleStatus'),
			method: "put",
			data
		})
	}
}
export default vehicleScheduling;