/**
 * 用户管理用到的接口
 */
import http from '@/utils/httpRequest.js'
import config from '@/utils/config.js'
const user = {
	//用户列表
	userList(data){
		return http({
			url: http.adornUrl('user/page'),
			method: "get",
			params:data
		})
	},
	//新增用户
	addUser(data){
		return http({
			url: http.adornUrl('user'),
			method: "post",
			data
		})
	},
	//编辑用户
	editUser(data){
		return http({
			url: http.adornUrl('user'),
			method: "put",
			data
		})
	},
	//查询用户信息接口
	getUserInfo(id){
		return http({
			url: http.adornUrl('user/'+id),
			method: "get"
		})
	},
	//删除用户
	deleteUser(id){
		return http({
			url: http.adornUrl('user/')+id,
			method: "delete"
		})
	},
	//修改用户是否为管理员
	modifyRole(data){
		return http({
			url: http.adornUrl('user/modifyRole'),
			method: "put",
			// params:data
			data
		})
	},
	//用户反馈列表
	feedback(data){
		return http({
			url: http.adornUrl('feedback/pageList'),
			method: "get",
			params:data
		})
	},
	//用户禁用启用
	editLockFlag(data){
		return http({
			url: http.adornUrl('user/editLockFlag'),
			method: "put",
			data
		})
	}
}
export default user;